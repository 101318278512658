import { Model } from "@vuex-orm/core";
import Device from "@/store/models/device.model";
import DeviceState from "@/store/models/device-state.model";
import ConsoleCommand from "@/store/models/console-command.model";
import { SEND_COMMAND, SERVER_COMMAND_ACK, SOCKET_SEND } from "@/store/actions.type";
import { v4 } from 'uuid';
import Notification from "@/store/models/notification.model";
import { apiUrl } from "@/common/endpoint.const";

export default class Console extends Model {
	static entity = 'console';

	static fields() {
		return {
			id: this.attr(null),
			deviceId: this.attr(null),
			device: this.belongsTo(Device, 'deviceId'),
			cmdHistory: this.hasMany(ConsoleCommand, 'consoleId'),
		}
	}

	async clearHistory() {
		// Trova e elimina tutti i ConsoleCommand associati a questa console
		const relatedCommands = await ConsoleCommand.query().where('consoleId', this.id).get();

		// Creare un array di ID da eliminare
		const deleteIds = relatedCommands.map(command => command.id);

		// Elimina effettivamente i comandi
		if (deleteIds.length > 0) {
			for (let id of deleteIds) {
				await ConsoleCommand.delete(id);
			}
		}

		return this;
	}

	async sendCommand({ cmd, params, user }, deviceID = undefined) {

		if (user) {
			localStorage.setItem("checkPass", 1);
			const userAct = JSON.parse(localStorage.getItem('user'));
			const result = await Console.update({
				data: {
					id: this.id,
					cmdHistory: [{
						uuid: v4(),
						cmd,
						params,
						timestamp: (new Date).toISOString(),
						from: `operator#${userAct.id}`,
						to: `device#${this.device ? this.device.id : deviceID}`,
						status: 0,
						consoleId: this.id
					}]
				},
				insert: ['consoleCommands']
			});
			console.log("RESULT: ", result);
			const elem = await this.$store().dispatch(
				SOCKET_SEND,
				{
					event: 'operator:cmd',
					data: result.consoleCommands[0].$toJson()
				},
				{ root: true }
			);

			console.log("ELEM: ", elem);
			if (elem) {
				return this;
			}
			else {
				return false
			};
		}
	}
}

export const consoleModule = {
	state: {
		cmdCursor: -1,
		activeConsole: null,
	},
	actions: {
		async [SEND_COMMAND]({ rootState, state, dispatch }, _) {

			const sendResult = await dispatch(SOCKET_SEND, {
				event: 'operator:cmd',
				data: {
					cmd: "ping",
					params: []
				}
			}, { root: true })
			return sendResult;
		},
		async [SERVER_COMMAND_ACK](context, ack) {
			const dataACK = ack.data;

			let token = localStorage.getItem('token');
			let JSONAuth = {
				headers: {
					authorization: `Bearer ${token}`,
					'athena-deviceid': localStorage.getItem("deviceId")
				}
			}
			if (dataACK != undefined) {
				let uuid = dataACK.uuid;
				const cmd = ConsoleCommand.query().with('console').where('uuid', uuid).first();
				localStorage.setItem("lastUUID", uuid);
				await fetch(`${apiUrl}/device/command-data/${uuid}`, JSONAuth)
					.then((response) => {
						return response.json();
					})
					.then(async (data) => {

						console.log('Success:', data);
						if (!data) {
							localStorage.setItem("checkPass", 0);
							return
						}
						console.log("dataACK: ", dataACK);
						if (dataACK.sent) {
							localStorage.setItem("checkPass", 1);
							console.log("INVIATO!");
							ConsoleCommand.update({
								data: {
									id: cmd.id,
									status: 1,
								}
							});
							if (!data.params.includes("not-show-noty")) {
								Notification.insert({
									data: {
										icon: 'mdi-progress-upload',
										color: 'green darken-1',
										title: 'Comando inviato al Device',
										subtitle: 'Il comando è stato inviato al Device ed è in attesa di essere eseguito',
										actionLabel: 'OK',
										actionIcon: 'mdi-check',
										deviceId: 1,
									}
								});
							}
						}
						else if (dataACK.received) {
							localStorage.setItem("checkPass", 0);
							console.log("RICEVUTO!");
							DeviceState.update({
								data: {
									id: 1,
									is_connected: true,
								}
							});
							ConsoleCommand.update({
								data: {
									id: cmd.id,
									status: 2,
								}
							});
							if (!data.params.includes("not-show-noty")) {
								Notification.insert({
									data: {
										icon: 'mdi-progress-upload',
										color: 'green darken-1',
										title: 'Comando ricevuto dal Device',
										subtitle: 'Il comando è stato ricevuto dal Device ed è in attesa di essere eseguito',
										actionLabel: 'OK',
										actionIcon: 'mdi-check',
										deviceId: 1,
									}
								});
							}
						}
					})
					.catch((error) => {
						console.error('Error:', error);
					});
			}
			else {
				let checkPass = localStorage.getItem("checkPass");
				console.log("CHECKPASS: ", checkPass);
				console.log("ACK: ", !ack.received);
				if (!ack.received && checkPass != 0) {
					DeviceState.update({
						data: {
							id: 1,
							is_connected: false,
							wifi_is_on: false,
						}
					});
					const uuid = localStorage.getItem("lastUUID");
					await fetch(`${apiUrl}/device/command-data/${uuid}`, JSONAuth).then((response) => {
						return response.json();
					})
						.then(async (data) => {
							if (data) {
								const cmd = ConsoleCommand.query().with('console').where('uuid', data.uuid).first();
								ConsoleCommand.update({
									data: {
										id: cmd.id,
										status: -1,
									}
								});
								if (!data.params.includes("not-show-noty")) {
									Notification.insert({
										data: {
											icon: 'mdi-progress-upload',
											color: 'red darken-1',
											title: `Comando NON eseguito!`,
											subtitle: 'Non è stato possibile inviare il comando al device',
											actionLabel: 'OK',
											actionIcon: 'mdi-check',
											deviceId: 1,
										}
									});
								}
							}
						})
				}
			}
		}
	},
}