<template>
  <div class="home">
    <div class="d-flex align-center justify-center">
      <v-card class="ma-4" style="min-width: 800px;">
        <v-toolbar>

          <v-toolbar-title>Dispositivi</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-view-module</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row v-if="isFetching">
          <v-col cols="12" class="d-flex justify-center align-center" self-align="center">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <device-list v-else :devices="devices"></device-list>
      </v-card>
    </div>
  </div>
</template>

<script>

import DeviceList from "@/components/device/DeviceList";
import {FETCH_ALL_DEVICES, SOCKET_AUTH, SOCKET_CONNECT} from "@/store/actions.type";
import Device from "@/store/models/device.model";
import {RESET_DEVICE} from "@/store/mutations.type";

export default {
  name: 'Home',
  components: {
    DeviceList
  },
  computed: {
    devices(){
      var devices = Device.getters('devices');
      console.log("filter: ", config.filter, config.filter.length);
      if (config.filter.length > 0) {
        var filteredDevices = devices.filter(d => config.filter.includes(d.id));
 //       var filteredDevices = devices.filter(d =>  d.id == 6 || d.id == 7);
        console.log("filteredDevices: " + JSON.stringify(filteredDevices));
        return filteredDevices;      
      }
      else {
        return devices;
      }
      
    },
    isFetching(){
      return Device.getters('isFetching');
    }
  },
  created() {
    Device.dispatch(RESET_DEVICE)
    Device.dispatch(FETCH_ALL_DEVICES)
  }
}
</script>
