<template>
	<v-card>
		<v-dialog v-model="dialog" max-width="500px">
			<v-card>
				<v-card-title class="headline"></v-card-title>
				{{ imageActualName }}
				<v-card-text>
					<div class="modal-container__image" v-if="actualType == 'image'">
						<v-img :src="selectedUrl" max-width="100%" max-height="100%"></v-img>
					</div>
					<div class="modal-container__vocal" v-if="actualType == 'vocal'">
						<audio controls
									 class="my-audio-player"
									 controlsList="nodownload"
									 style="width: 100%;">
							<source :src="selectedUrl" />
							Your browser does not support the audio element.
						</audio>
					</div>
					<v-select v-model="selectedDirection"
										:items="direction"
										item-text="direction"
										item-value="id"
										label="Seleziona la direzione">
					</v-select>
					<v-select v-model="selectedContact"
										:items="contact"
										item-text="contact"
										item-value="id"
										label="Seleziona il contatto">
					</v-select>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close">Chiudi</v-btn>
					<v-btn color="blue darken-1" text @click="save">Salva</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<div v-if="unknownMessage.length > 0">
			<v-subheader>File incerti</v-subheader>
			<v-expansion-panels>
				<v-expansion-panel v-for="(mess, i) in unknownMessage" :key="i">
					<v-expansion-panel-header>
						Il sistema ha rilevato il seguente messaggio!
						<!-- <div v-for="(contact, j) in mess.contact" :key="j"> -->
						<!-- <v-list-item> -->
						<!-- <v-list-item-avatar> -->
						<!-- <avatar :username="contact.name"></avatar> -->
						<!-- </v-list-item-avatar> -->
						<!-- <v-list-item-content> -->
						<!-- <v-list-item-title>{{ contact.name }}</v-list-item-title> -->
						<!-- <v-list-item-subtitle>data messaggio {{ ProxyAddOneHour(mess.created_at) }}</v-list-item-subtitle> -->
						<!-- </v-list-item-content> -->
						<!-- </v-list-item> -->
						<!-- </div> -->
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-divider></v-divider>
						<template>
							<div class="image-container">
								<v-row>
									<template v-if="mess.fileType == 'vocal'">
										<!-- create audio tag -->
										<div v-for="(messagElem, index) in mess.content.replace('[','').replace(']','').split(',')" :key="index">
											<div class="vocal-container">
												<v-btn color="red" @click="openDialogue(apiUrl + '/' + messagElem + '?access_token=' + token + '&deviceid=' + localStorage.getItem('deviceId'),'vocal', messagElem)" style="margin-right:10px;">
													<v-icon>mdi-play</v-icon>
												</v-btn>
												<div>
													<audio controls
																 class="my-audio-player"
																 controlsList="nodownload">
														<source :src="apiUrl+'/'+messagElem + '?access_token=' + token + '&deviceid=' + localStorage.getItem('deviceId')" />
														Your browser does not support the audio element.
													</audio>
												</div>
											</div>
										</div>
									</template>
									<template v-if="mess.fileType == 'image'">
										<template v-for="(messagElem, j) in mess.content.replace('[','').replace(']','').split(',')">
											<v-col cols="12" md="2" sm="6" :key="i">
												<div class="single-cont-image" :key="j">
													<img :src="apiUrl + '/' + messagElem + '?access_token=' + token + '&deviceid=' + localStorage.getItem('deviceId')" @click="openDialogue(apiUrl + '/' + messagElem + '?access_token=' + token + '&deviceid=' + localStorage.getItem('deviceId'),'image', messagElem)" />
												</div>
											</v-col>
										</template>
									</template>
								</v-row>
							</div>
						</template>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
	</v-card>
</template>

<script>
	import Avatar from 'vue-avatar';
	import Swal from 'sweetalert2';
	import { addOneHour } from '../../common/utils';
	import axios from '../../axios';
	import ChatMessageUtils from "../../utils/ChatMessageUtils";
	import ChatMessage from "../../store/models/chat-message.model";
	import { apiUrl } from "@/common/endpoint.const";
	export default {
		name: "UnknownChat",
		props: ['apiUrl', 'token'],
		components: {
			Avatar
		},
		data: () => ({
			dialog: false,
			selectedDirection: null,
			selectedContact: null,
			unknownMessage: null,
			contact: [],
			idContact: [],
			selectedUrl: "",
			actualType: "",
			direction: ["IN ENTRATA", "IN USCITA"],
			imageActualName: "",
		}),
		computed: {
		},
		methods: {
			async openDialogue(Url, type, actualImage) {
				this.imageActualName = actualImage;
				// for (let i = 0; i < contactParams.length; i++) {
				// this.contact[i] = contactParams[i].name;
				// }
				await axios.get(`${this.apiUrl}/device/contact-limit`).then((data) => {
					console.log("data: ", data.data);
					for (let i = 0; i < data.data.length; i++) {
						this.contact[i] = data.data[i].label;
						this.idContact[i] = data.data[i].value;
					}
				});
				this.selectedUrl = Url;
				this.dialog = true;
				this.actualType = type;
				console.log("Selected image url: ", this.selectedUrl);
				console.log("Selected actualType: ", this.actualType);
				console.log("Selected dialog: ", this.dialog);
			},
			ProxyAddOneHour(date) {
				return addOneHour(date);
			},
			close() {
				this.dialog = false;
			},
			getContactId() {
				console.log(this.selectedContact);
				for (let i = 0; i < this.contact.length; i++) {
					if (this.contact[i] == this.selectedContact) {
						return this.idContact[i];
					}
				}
			},
			async getunknownMessages() {
				this.baseURL = this.apiUrl;
				let urlChatMessage = `${this.baseURL}/device/unknown-fetch-messages`;
				this.unknownMessage = await axios.get(urlChatMessage);

				if (this.unknownMessage && this.unknownMessage.status == 200) {
					this.unknownMessage = this.unknownMessage.data;
				}
			},
			async save() {
				try {
					this.dialog = false;
					if (this.selectedDirection == null || this.selectedDirection == undefined) {
						Swal.fire({
							title: 'Errore',
							text: 'Non è stata specificata alcuna direzione',
							icon: 'error',
							confirmButtonText: 'Ok'
						});
						return;
					}
					let numberDirection = this.selectedDirection == "IN ENTRATA" ? 0 : 1;
					let imageName = this.selectedUrl.split('/').pop().split('?')[0];
					if (this.selectedContact == null || this.selectedContact == undefined) {
						Swal.fire({
							title: 'Errore',
							text: 'Non è stato specificato alcun contatto',
							icon: 'error',
							confirmButtonText: 'Ok'
						});
						return;
					}
					let contactId = this.getContactId();
					let url = this.apiUrl + '1/change-media-information';

					console.log("Image name: " + imageName);
					console.log("Number direction: " + numberDirection);
					console.log("Selected contact: " + this.selectedContact + " -> " + contactId);
					console.log("Url: ", url);
					if (imageName == null || imageName == undefined) {
						Swal.fire({
							title: 'Errore',
							text: 'Non è stato specificato alcun nome per l\'immagine',
							icon: 'error',
							confirmButtonText: 'Ok'
						});
						return;
					}
					Swal.fire({
						icon: 'warning',
						title: 'Sei sicuro di voler effettuare questo cambiamento?',
						text: 'Non sarà possibile tornare indietro!',
						showDenyButton: true,
						showCancelButton: true,
						confirmButtonText: 'Salva',
						denyButtonText: `Non Salvare`,
					}).then(async (result) => {
						if (result.isConfirmed) {
							const user = JSON.parse(localStorage.getItem('user'));
							console.log("USER: ", user.username);
							let res = await axios.post(this.apiUrl + `/device/change-media-information`, {
								imageName: imageName,
								direction: numberDirection,
								contactId: contactId,
								userUpdated: user.username
							});
							console.log("Response: ", res);
							if (res.data.status) {
								Swal.fire({
									title: 'Successo',
									text: 'Salvataggio avvenuto con successo',
									icon: 'success',
									confirmButtonText: 'Ok'
								});
								this.getunknownMessages();
								let lastMessage = res.data.messageLast[0];
								console.log("New message arrive: ", lastMessage);
								ChatMessageUtils.newMessageArrive(lastMessage);
								ChatMessage.insert({
									data: {
										...lastMessage
									}
								})
							}
							else {
								Swal.fire({
									title: 'Errore',
									text: 'Errore durante il salvataggio',
									icon: 'error',
									confirmButtonText: 'Ok'
								});
							}
							this.selectedContact = null;
							this.selectedDirection = null;
						}
						else if (result.isDenied) {
							Swal.fire('I cambiamenti non sono stati salvati!', '', 'info')
						}
					})

				}
				catch (e) {
					console.log("Log errore: ", e);
					Swal.fire({
						title: 'Errore',
						text: 'Errore durante il salvataggio',
						icon: 'error',
						confirmButtonText: 'Ok'
					});
				}
			}
		},
		async mounted() {
			this.getunknownMessages();
			const ct = this.$el.querySelector('#chat-timeline');
			ct.scrollTop = ct.scrollHeight;
		},

	}
</script>

<style scoped>
	.image-container {
		display: flex;
		margin-top: 10px;
		flex-wrap: wrap;
	}

	.single-cont-image > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.vocal-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 10px;
	}

	.modal-container__vocal {
		padding-left: 50px;
		height: auto;
		padding-right: 50px;
	}

	.modal-container__image {
		padding-left: 50px;
		height: 360px;
		padding-right: 50px;
	}
</style>
