<template>
	<v-list two-line>

		<v-list-item v-for="device in devices"
								 :key="device.id"
								 @click="beforeNavigate(device.id)">
			<v-list-item-avatar>
				<avatar :username="device.model"></avatar>
			</v-list-item-avatar>

			<v-list-item-content>
				<v-list-item-title v-text="device.name"></v-list-item-title>
				<v-list-item-subtitle v-text="device.imei1"></v-list-item-subtitle>
			</v-list-item-content>

			<v-list-item-action>
				<v-btn :to="`/device/dashboard`" icon>
					<v-icon color="grey lighten-1">mdi-arrow-right-drop-circle</v-icon>
				</v-btn>
			</v-list-item-action>
		</v-list-item>

		<v-divider inset></v-divider>

	</v-list>
</template>

<script>
	import Avatar from 'vue-avatar';

	export default {
		name: "DeviceList",
		components: {
			Avatar
		},
		props: {
			devices: Array
		},
		methods: {
			beforeNavigate(deviceid) {
				localStorage.setItem("deviceId", deviceid);
				this.$router.push(`/device/dashboard`);
			}
		}
	}
</script>

<style scoped>
</style>