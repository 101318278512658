<template xmlns="http://www.w3.org/1999/html">
	<div v-if="device">
		<v-row align="center" class="pa-4">
			<v-col cols="3" align-self="start">
				<v-card>
					<v-card-title>
						Elenco sensori smartphone
					</v-card-title>
					<v-card-text>
						<v-list two-line dense>
							<template v-for="sensor in device.sensors">
								<v-list-item :key="sensor.id" @click="selectSensor(sensor)">
									<v-list-item-avatar>
										<v-avatar :color="sensor.type | relevanceTypeIconColor">
											<v-icon dark>{{ sensor.type | sensorTypeIcon }}</v-icon>
										</v-avatar>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title>
											{{sensor.type | sensorTypeLabel}} {{sensor.identifier}}
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>

								<v-divider :key="'d'+sensor.id"></v-divider>
							</template>
						</v-list>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col v-if="selectedSensor" cols="6" align-self="start">
				<v-card>
					<v-card-title>
						Sensore: {{selectedSensor.type | sensorTypeLabel}} {{selectedSensor.identifier}}
					</v-card-title>
					<v-card-subtitle>
						{{selectedSensor.type | sensorTypeLabel}}
					</v-card-subtitle>
					<v-card-text>
						<div v-if="selectedSensor.type === 1">
							<v-btn color="primary" @click="takePic(selectedSensor.identifier)" :disabled="disabledClean[selectedSensor.identifier]">Scatta foto</v-btn>
						</div>
						<div v-if="selectedSensor.type === 0">
							<v-banner>
								Acquisizioni programmate
							</v-banner>
							<v-list three-line>
								<v-list-item v-if="device.schedule.length === 0">
									<v-list-item-content>
										<v-list-item-title>
											Nessuna schedulazione programmata
										</v-list-item-title>
										<v-list-item-subtitle>
											Questo sensore non ha ancora nessuna acquisizione in programma, impostane una cliccando sul tasto a destra
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-btn color="primary" @click="openScheduleDialog(null)">
											<v-icon>mdi-plus</v-icon>
											Programma
										</v-btn>
									</v-list-item-action>
								</v-list-item>
								<v-list-item v-else>
									<v-list-item-content>
										<v-list-item-title>
											Aggiungi acquisizione
										</v-list-item-title>
										<v-list-item-subtitle>
											imposta una nuova programmazione cliccando sul tasto a destra
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-btn @click="openScheduleDialog(null)" color="primary">
											<v-icon>mdi-plus</v-icon>
											Programma
										</v-btn>
									</v-list-item-action>
								</v-list-item>
								<v-divider></v-divider>
								<template v-for="(schedule,index) in schedule">
									<v-list-item :key="schedule.id">
										<v-list-item-avatar>
											{{schedule.name}}
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>Dalle {{schedule.start_date}} alle {{schedule.end_date}} ogni {{schedule.duration | sec2min}}</v-list-item-title>
											<v-list-item-subtitle>Creata il {{proxyaddOneHour(schedule.created_at)}}</v-list-item-subtitle>
											<v-list-item-subtitle>Registrazione microfono solamente a schermo spento: {{ requireScreenLocked != null ? requireScreenLocked ? 'Abilitato' : 'Disabilitato' : 'Sconosciuto' }} <span style="color:red" v-if="notSure">(Dato non certo)</span></v-list-item-subtitle>
											<v-list-item-subtitle>GeoFence: {{schedule.hasGeoFence | yesOrNo}} - WiFi Fence: {{schedule.hasWifiFence | yesOrNo}}</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action>
											<v-btn color="yellow darken-3" dark class="mt-2" @click="openScheduleDialog(schedule,index)" style="width:140px;">
												Modifica
												<v-icon>mdi-pencil</v-icon>
											</v-btn>
											<v-btn v-if="schedule.active" :disabled="waitButtons" color="red darken-1" dark class="mt-2" @click="suspendSchedule(schedule,index)" style="width:140px;">
												Sospendi
												<v-icon>mdi-stop</v-icon>
											</v-btn>
											<v-progress-circular indeterminate v-else-if="loadingIndex[index]"></v-progress-circular>
											<v-btn v-else :disabled="waitButtons" color="green darken-1" dark class="mt-2" @click="restartSchedule(schedule,index)" style="width:140px;">
												Riprendi
												<v-icon>mdi-play</v-icon>
											</v-btn>
											<v-btn :disabled="waitButtons" color="yellow darken-1" dark class="mt-2" @click="toggleScreenRequires" style="width:140px;">
												Modalità
											</v-btn>
											<v-btn color="red darken-1" dark class="mt-2" v-if="!schedule.active" @click="deleteSchedule(schedule,index)" style="width:140px;">
												Elimina
												<v-icon>mdi-trash-can</v-icon>
											</v-btn>
										</v-list-item-action>
									</v-list-item>
									<v-divider :key="'d'+schedule.id"></v-divider>
								</template>
							</v-list>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog v-if="editingSchedule" width="40%" v-model="scheduleDialog">
			<v-card style="height: 600px !important;">
				<v-card-title>
					Aggiungi programmazione per il sensore
				</v-card-title>
				<v-card-subtitle>
					{{selectedSensor.type | sensorTypeLabel}} {{selectedSensor.identifier}}
				</v-card-subtitle>
				<v-card-text>
					<v-form id="new-schedule">
						<p v-if="errors.length">
							<b style="color: red">Sono stati trovati i seguenti errori</b>
							<ul>
								<li style="color: red" v-for="error in errors">{{error}}</li>
							</ul>
						</p>
						<v-row class="mt-2">
							<v-row>
								<v-col cols="4">
									<v-text-field label="Nome programmazione"
																v-model="editingSchedule.name"
																:messages="['È possibile scegliere un nome per la programmazione']"
																placeholder="Nome programmazione"
																outlined></v-text-field>
								</v-col>
								<v-col cols="4">
									&nbsp;
								</v-col>
								<v-col cols="4">
									<v-btn v-if="adding" color="primary" block @click="addSchedule(editingSchedule)">
										<v-icon>mdi-content-save</v-icon>
										Salva
									</v-btn>
									<v-btn v-else-if="editingSchedule.loading" color="primary" block @click="addSchedule(editingSchedule)">
										<v-progress-circular indeterminate></v-progress-circular>
									</v-btn>
									<v-btn v-else color="primary" block @click="formatSchedule(editingSchedule,actualIndex)">
										<v-icon>mdi-content-save</v-icon>
										Modifica
									</v-btn>
								</v-col>
							</v-row>
						</v-row>
						<v-row>
							<v-col cols="4">
								<p class="text-body-1 ma-0 pa-0">Ora inizio</p>
								<div class="d-flex align-center ma-0 pa-0">
									<v-select style="width: 40%"
														v-model="startHour"
														:items="hours"></v-select>
									<v-spacer></v-spacer>
									<v-select style="width: 40%"
														v-model="startMinute"
														:items="minutes"></v-select>
								</div>
							</v-col>
							<v-col cols="4">
								<p class="text-body-1 ma-0 pa-0">Ora fine</p>
								<div class="d-flex align-center ma-0 pa-0">
									<v-select style="width: 40%"
														v-model="endHour"
														:items="endHours"></v-select>
									<v-spacer></v-spacer>
									<v-select style="width: 40%"
														v-model="endMinute"
														:items="minutes"></v-select>
								</div>
							</v-col>
							<v-col cols="3">
								<p class="text-body-1 ma-0 pa-0">Durata in minuti</p>
								<div class="d-flex align-center ma-0 pa-0">
									<v-select style="width: 40%"
														v-model="actualDuration"
														:items="durationOptions"></v-select>
								</div>
							</v-col>
						</v-row>
						<v-subheader>
							<v-icon class="mr-2">mdi-crosshairs-gps</v-icon>
							GeoFence
						</v-subheader>
						<v-row class="my-2">
							<v-row>
								<v-col cols="4">
									<v-text-field label="Latitudine"
																v-model="editingSchedule.lat"
																:messages="['Coordinate espresse in valore decimale']"
																placeholder="37.4152614"
																outlined></v-text-field>
								</v-col>
								<v-col cols="4">
									<v-text-field label="Longitudine"
																v-model="editingSchedule.lon"
																:messages="['Coordinate espresse in valore decimale']"
																placeholder="15.2369584"
																outlined></v-text-field>
								</v-col>
								<v-col cols="4">
									<v-text-field v-model="editingSchedule.radius"
																label="Raggio d'azione"
																:messages="['espresso in metri ad es 100 o 200']"
																placeholder="Raggio"
																outlined></v-text-field>
								</v-col>
							</v-row>
						</v-row>
						<v-subheader>
							<v-icon class="my-2">mdi-wifi</v-icon>
							WiFi Fence
						</v-subheader>
						<v-row class="mt-2">
							<v-row>
								<v-col cols="4">
									<v-select :items="wifiList"
														label="SSID WiFi"
														v-model="editingSchedule.ssid"
														:messages="['Nome della linea WiFi']"
														placeholder="Nome wifi"
														item-text="ssid"
														item-value="ssid"
														outlined>
									</v-select>
								</v-col>
								<v-col cols="4">
									&nbsp;
								</v-col>
								<v-col cols="4">
									&nbsp;
								</v-col>
							</v-row>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import Device from "@/store/models/device.model";
	import DeviceInfo from "@/components/device/DeviceInfo";
	import ScheduleMenu from "@/components/schedule/ScheduleMenu";
	import { apiUrl } from "@/common/endpoint.const";
	import WiFi from "@/store/models/wifi.model";
	import { hours } from "@/common/hours";
	import { endHours } from "@/common/endHours";
	import { minutes } from "@/common/minutes";
	import Notification from "@/store/models/notification.model";
	import Console from "@/store/models/console.model";
	import Sensor from "@/store/models/sensor.model";
	import Schedule from "@/store/models/schedule.model";
	import { addOneHour } from "@/common/utils";
	import axios from '../../axios';
	import Swal from 'sweetalert2';
	import { LogEventTypes } from "../../utils/LogEventTypes";
	import { LogStatus } from "../../utils/LogStatus";

	export default {
		name: "DeviceSensors.vue",
		components: {
			DeviceInfo,
			ScheduleMenu,
		},

		data() {
			return {
				selectedSensor: null,
				scheduleDialog: false,
				adding: null,
				schedule: null,
				token: localStorage.getItem('token'),
				errors: [],
				loadingIndex: [],
				hours,
				endHours,
				actualDuration: null,
				minutes,
				startHour: '',
				startMinute: '',
				endHour: '',
				endMinute: '',
				newSchedule: {
					name: null,
					actualDuration: null,
					start_date: null,
					end_date: null,
					duration: null,
					lat: null,
					lon: null,
					radius: null,
					ssid: null,
				},
				editingSchedule: null,
				actualIndex: null,
				requireScreenLocked: null,
				notSure: false,
				waitButtons: false,
				oldSchedule: null,
				disabledClean: [false, false],
			}
		},
		methods: {
			async takePic(identifier) {
				var consoleCommand = null;

				await Console.insertOrUpdate({
					data: {
						deviceId: this.device.id,
						device: this.device,
					}
				}).then((data) => {
					consoleCommand = data.console[0];
				});

				var result = await consoleCommand.sendCommand(
					{
						cmd: `PHOTO`,
						params: [identifier],
						user: JSON.parse(localStorage.getItem('user')) || {}
					},
					this.device.id
				);

				this.disabledClean[identifier] = true;
				setTimeout(() => {
					this.disabledClean[identifier] = false;
				}, 2000);
			},
			async deleteSchedule(schedule, index) {
				var success = false;
				try {
					await Swal.fire({
						title: 'Sei sicuro di voler eliminare questa programmazione?',
						text: "Questa azione non può essere annullata!",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Si, elimina!',
						cancelButtonText: 'Annulla'
					}).then(async (result) => {
						if (result.isConfirmed) {
							console.log("Schedule: ", schedule);
							console.log("index: ", index);

							let idSchedule = schedule.id;
							const result = await axios.delete(`${apiUrl}/device/schedule/${idSchedule}`, {
								headers: {
									'Content-Type': 'application/json',
								}
							});
							console.log("result: ", result);
							if (result.status === 200) {
								await Swal.fire({
									title: 'Eliminazione avvenuta con successo',
									icon: 'success',
									showCancelButton: false,
									confirmButtonText: 'Ok',
								});
								this.schedule.splice(index, 1);
								success = true;
							}
						}
					})
				} catch (e) {
					console.log(e);
				}
			},
			proxyaddOneHour(date) {
				return addOneHour(date)
			},
			selectSensor(sensor) {
				this.selectedSensor = sensor;
			},
			checkIfScheduleIsOverlap(actualSchedule) {

				let actualStart_date = actualSchedule.start_date.split(':');
				let actualEnd_date = actualSchedule.end_date.split(':');
				let actualStart_date_hour = Number(actualStart_date[0]);
				let actualStart_date_minute = Number(actualStart_date[1]);
				let actualEnd_date_hour = Number(actualEnd_date[0]);
				let actualEnd_date_minute = Number(actualEnd_date[1]);

				for (let i = 0; i < this.schedule.length; i++) {
					if (this.schedule[i].id !== actualSchedule.id) {
						let start_date = this.schedule[i].start_date.split(':');
						let end_date = this.schedule[i].end_date.split(':');
						let start_date_hour = Number(start_date[0]);
						let start_date_minute = Number(start_date[1]);
						let end_date_hour = Number(end_date[0]);
						let end_date_minute = Number(end_date[1]);
						if (
							(actualStart_date_hour < end_date_hour ||
								(actualStart_date_hour === end_date_hour &&
									actualStart_date_minute <= end_date_minute)) &&
							(actualEnd_date_hour > start_date_hour ||
								(actualEnd_date_hour === start_date_hour &&
									actualEnd_date_minute >= start_date_minute))
						) {
							console.log(
								`Sovrapposizione trovata tra actualSchedule.id=${actualSchedule.id
								} e this.schedule[${i}].id=${this.schedule[i].id}`
							);
							return true;
						}
					}
				}
			},
			async formatSchedule(schedule, index) {
				console.log("SCHEDULE: ", schedule);
				schedule.end_date = this.endHour + ':' + this.endMinute;
				schedule.start_date = this.startHour + ':' + this.startMinute;
				if (this.actualDuration[0] == "0") {
					schedule.duration = Number(this.actualDuration[1]) * 60;
				}
				else {
					schedule.duration = Number(this.actualDuration) * 60;
				}
				var success = await this.editScheduleFunc(schedule, index);

				this.oldSchedule = null;
			},
			openScheduleDialog(schedule = null, index = null) {
				if (index !== null) {
					this.actualIndex = index;
				}
				this.scheduleDialog = true;
				this.oldSchedule = { ...schedule };
				this.editingSchedule = schedule ?? this.newSchedule;

				if (schedule != null) {
					this.startHour = schedule ? schedule.start_date.split(':')[0] : '';
					this.startMinute = schedule ? schedule.start_date.split(':')[1] : '';
					this.endHour = schedule ? schedule.end_date.split(':')[0] : '';
					this.endMinute = schedule ? schedule.end_date.split(':')[1] : '';
					let stringDuration = (schedule.duration / 60).toString();
					let lengthElem = (schedule.duration / 60).toString().length;
					this.actualDuration = lengthElem == 1 ? '0' + stringDuration : stringDuration;
					//this.editingSchedule.duration = schedule ? lengthElem == 1 ? '0'+stringDuration : stringDuration : '1';
					this.editingSchedule.lat = schedule ? schedule.lat : null;
					this.editingSchedule.lon = schedule ? schedule.lon : null;
					this.editingSchedule.radius = schedule ? schedule.radius : null;
					this.editingSchedule.ssid = schedule ? schedule.ssid : null;
				}
				else {
					this.scheduleDialog = true;
					this.editingSchedule = this.newSchedule;
					this.startHour = '';
					this.startMinute = '';
					this.endHour = '';
					this.endMinute = '';
					this.editingSchedule.duration = '01';
					this.editingSchedule.lat = null;
					this.editingSchedule.lon = null;
					this.editingSchedule.radius = null;
					this.editingSchedule.ssid = null;
					this.adding = true;
				}


				this.adding = schedule === null;
				console.log(schedule);
			},
			closeScheduleDialog() {
				this.scheduleDialog = false;
				this.editingSchedule = null
				this.adding = null;
			},
			async suspendSchedule(schedule, index) {
				console.log("SCHEDULE: ", schedule);
				this.schedule[index].active = false;
				this.editingSchedule = this.schedule[index];
				const success = await this.editScheduleFunc(schedule, index, true);
				this.blockButtons();

				this.commandConsole.sendCommand({
					cmd: "RESTART_RECORDER",
					params: ["not-show-noty"],
					user: JSON.parse(localStorage.getItem('user')) || {}
				}, localStorage.getItem("deviceId"));
			},
			async restartSchedule(schedule, index) {
				this.schedule[index].active = true;
				this.editingSchedule = this.schedule[index];
				const success = await this.editScheduleFunc(schedule, index, true);
				this.blockButtons();
			},
			blockButtons() {
				this.waitButtons = true;
				setTimeout(() => { this.waitButtons = false; }, 5000)
			},
			async addSchedule(schedule) {
				if (/[!@#$%^&*(),.?":{}|<>]/.test(schedule.name)) {
					Swal.fire({
						title: 'Attenzione!',
						text: 'Il nome della schedulazione non può contenere caratteri speciali!',
						icon: 'error',
						confirmButtonText: 'Ok',
						reverseButtons: true
					})
					return;
				}
				if (!schedule.name) {
					Swal.fire({
						title: 'Attenzione!',
						text: 'Il nome della schedulazione è obbligatorio!',
						icon: 'error',
						confirmButtonText: 'Ok',
						reverseButtons: true
					})
				}

				schedule.name = schedule.name.replace(/\s/g, '-');
				schedule.start_date = this.startHour + ':' + this.startMinute
				schedule.end_date = this.endHour + ':' + this.endMinute
				schedule.duration = this.actualDuration * 60;
				if (!this.checkForm()) return;
				if (this.checkIfScheduleIsOverlap(schedule)) {
					Swal.fire({
						title: 'Attenzione!',
						text: 'Abbiamo riscontrato delle sovrapposizione di orario con altri elementi inseriti, cambia l\'orario per poter salvare un nuovo elemento!',
						icon: 'error',
						confirmButtonText: 'Ok!',
					})
					return;
				}

				schedule.loading = true;
				let userId = JSON.parse(window.localStorage.getItem('user')).id;
				console.log(userId);
				await Device.api().post(`${apiUrl}/device/schedule`, schedule,
					{
						persistBy: 'insert',
						persistOptions: {
							insertOrUpdate: ['users', 'devices']
						}
					})
					.then(async (response) => {
						this.schedule = await this.getSchedule();
						if (this.schedule.response?.data) {
							this.schedule = this.schedule.response.data;
						}
					})
				this.closeScheduleDialog()
			},
			async editScheduleFunc(schedule, index, check = true) {
				console.log("duration: ", this.actualDuration);
				if (/[!@#$%^&*(),.?":{}|<>]/.test(schedule.name)) {
					Swal.fire({
						title: 'Attenzione!',
						text: 'Il nome della schedulazione non può contenere caratteri speciali!',
						icon: 'error',
						confirmButtonText: 'Ok',
						reverseButtons: true
					})
					return;
				}
				this.schedule[index] = schedule;
				this.schedule[index].name = this.schedule[index].name.replace(/\s/g, '-');
				console.log("this.schedule[index]: ", this.schedule[index]);
				if (!this.schedule[index]?.lat || schedule[index]?.lat.toString() === "0" || !this.schedule[index]?.lon || this.schedule[index]?.lon.toString() === "0" || !this.schedule[index]?.radius || this.schedule[index]?.radius.toString() === "0") {
					this.schedule[index].lat = null;
					this.schedule[index].lon = null;
					this.schedule[index].radius = null;
					this.schedule[index].hasGeoFence = false;
				}
				if (check && !this.checkForm()) {
					return;
				}
				if (this.checkIfScheduleIsOverlap(this.schedule[index])) {
					Swal.fire({
						title: 'Attenzione!',
						text: 'Abbiamo riscontrato delle sovrapposizione di orario con altri elementi inseriti, cambia l\'orario per poter salvare un nuovo elemento!',
						icon: 'error',
						confirmButtonText: 'Ok!',
					})
					return;
				}

				this.loadingIndex[index] = true;
				this.schedule[index].user = JSON.parse(localStorage.getItem('user')) || {};
				var success = false;
				await Device.api().put(`${apiUrl}/device/schedule`, this.schedule[index] ,
					{
						persistBy: 'insert',
						persistOptions: {
							insertOrUpdate: ['users', 'devices']
						}
					}).then(async data => {
						if (data.response.status == 200) {
							await this.commandConsole.sendCommand({ cmd: "ping", params: [], user: JSON.parse(localStorage.getItem('user')) || {} }, localStorage.getItem("deviceId"));
							this.$set(this.loadingIndex, index, false);
						}
						success = true;
					}).catch(err => {
						console.log(err);
						Notification.insert({
							data: {
								icon: 'mdi-alert',
								color: 'red darken-1',
								title: `Errore!`,
								subtitle: `Abbiamo riscontrato alcuni errori nel salvataggio dei dati!`,
								deviceId: localStorage.getItem("deviceId"),
							}
						});
					})

				this.closeScheduleDialog()
				return success;
			},
			checkForm() {
				this.errors = [];
				if (!this.editingSchedule.name)
					this.errors.push('Nome della schedulazione richiesto');
				if (!this.editingSchedule.start_date || !/([0-2][0-9]:[0-6][0-9])/.test(this.editingSchedule.start_date))
					this.errors.push('Orario d\'inizio non valido');
				if (!this.editingSchedule.end_date || !/([0-2][0-9]:[0-6][0-9])/.test(this.editingSchedule.end_date))
					this.errors.push('Orario di fine non valido');
				if (!this.editingSchedule.duration || isNaN(+this.editingSchedule.duration) || Math.floor(+this.editingSchedule.duration) === 0)
					this.errors.push('La durata è espressa in secondi, utilizza un numero diverso da 0');
				if (this.editingSchedule.lat || this.editingSchedule.lon || this.editingSchedule.radius) {
					if (!(this.editingSchedule.lat && this.editingSchedule.lon && this.editingSchedule.radius))
						this.errors.push('Per attivare il GeoFence devi compilare tutti campi')
				}
				return this.errors.length === 0;
			},
			async getSchedule() {
				const res = await Schedule.api().get(`${apiUrl}/device/schedule`, {
					persistBy: 'create'
				});
				if (res?.response?.data) return res;
				return [];
			},
			async getSensor() {
				const res = await Sensor.api().get(`${apiUrl}/device/sensors`, {
					persistBy: 'create'
				});
				if (res?.response?.data) return res;
				return [];
			},
			async toggleScreenRequires() {
				var text = "";
				const originalValue = this.requireScreenLocked;
				if (this.requireScreenLocked || this.requireScreenLocked == null) {
					text = "Si vuole veramente modificare la modalità di registrazione per permettere al dispositivo di registrare anche a schermo sbloccato?\nNB: Questo mostrerà un'indicatore verde all'utente";
				} else {
					text = "Si vuole impostare la modalità di registrazione in modo da registrare solo con lo schermo bloccato?";
				}
				const dialogMode = await Swal.fire({
					title: 'Modifica modalità di registrazione',
					text: text,
					icon: 'warning',
					showConfirmButton: true,
					confirmButtonText: 'Ok',
					showCancelButton: true,
					cancelButtonText: 'Annulla',
				});

				if (dialogMode.isConfirmed) {
					// Invio del comando per cambio modalità
					await this.commandConsole.sendCommand({
						cmd: `UPDATE_CONFIGURATION`,
						params: [
							"RECORDING_REQUIRES_SCREEN_LOCKED",
							this.requireScreenLocked != null ? !this.requireScreenLocked : false,
							"not-show-dashboard"
						],
						user: JSON.parse(localStorage.getItem('user') || '') || {}
					}, 1);

					this.blockButtons();

					// Per avere lo status di risposta ad un comando si può vedere solo nella history dei comandi dopo
					// Si effettua una comando che aggiorna a db la configurazione per prendere lo stato aggiornato dal telefono
					// In più questo permette di vedere se il comando UPDATE_CONFIGURATION è andato a buon fine

					// E' necessario attendere almeno un secondo perché lo status dei comandi precedenti venga aggiornato
					///TODO: Valutare in futuro di creare un metodo getHistory in Console
					// await new Promise(resolve => setTimeout(resolve, 200)); // Usato per debug, causa situazione di inviato, ma non ricevuto
					await new Promise(resolve => setTimeout(resolve, 1000));

					// Ricezione delle configurazioni aggiornate
					var printConfiguration = await this.commandConsole.sendCommand({
						cmd: "PRINT_CONFIGURATION",
						params: ["not-show-noty"],
						user: JSON.parse(localStorage.getItem('user')) || {}
					}, localStorage.getItem("deviceId"));

					// Prelevamento storia dei comandi mandati dalla pagina
					const commandHistory = printConfiguration?.cmdHistory;
					// Prelevamento risposta a comando UPDATE_CONFIGURATION
					const previousCommand = commandHistory[commandHistory.length - 1];
					// Prelevamento status risposta
					const statusUpdateConfiguraton = previousCommand.status;

					var logStatus;

					// Status: 0 - Non inviato
					// Non viene modificata la visualizzazione modalità, ma dato non certo
					// Status: 1 - Inviato
					// Viene modificata la visualizzazione modalità, ma dato non certo
					// Status: 2 - Inviato e ricevuto
					// Viene modificata la visualizzazione modalità, dato sicuro
					switch (statusUpdateConfiguraton) {
						case 0:
							this.notSure = true;
							logStatus = LogStatus.FAILURE;
							break;
						case 1:
						case 2:
							this.requireScreenLocked = this.requireScreenLocked != null ? !this.requireScreenLocked : false;
							localStorage.setItem("requireScreenLocked", this.requireScreenLocked);
							this.notSure = statusUpdateConfiguraton == 2 ? false : true;
							logStatus = statusUpdateConfiguraton == 2 ? LogStatus.SUCCESS : LogStatus.UNKNOWN;
							break;
					}
				}
			},
		},
		computed: {
			durationOptions() {
				let arr = []
				for (let i = 1; i <= 10; i++)
					arr.push(`${i}`.padStart(2, '0'));
				return arr;
			},
			device() {
				return Device.query().with(['state', 'sensors', 'schedule']).find(localStorage.getItem("deviceId"))
			},
			wifiList() {
				return WiFi.all();
			},
			commandConsole() {
				return Console.query()
					.with("device")
					.with("cmdHistory")
					.where("deviceId", localStorage.getItem("deviceId"))
					.first();
			},
		},
		async created() {
			// Device.dispatch(FETCH_DEVICE_SENSORS, localStorage.getItem("deviceId"))
			if (!this.commandConsole) {
				console.log('[CREATING CONSOLE...]')
				await Console.insertOrUpdate({
					data: {
						deviceId: localStorage.getItem("deviceId"),
						device: this.device,
					}
				}).then((data) => {
					this.commandConsole = data.console[0];
				});
			}
			this.schedule = await this.getSchedule();
			if (this.schedule.response?.data) {
				this.schedule = this.schedule.response.data;
			}
			this.selectedSensor = await this.getSensor();
			if (this.selectedSensor.response?.data) {
				this.selectedSensor = this.selectedSensor.response.data;
			}
			let i = 0;
			this.schedule.map(() => {
				this.loadingIndex[i] = false;
				i++;
			});

			console.log("Schedule", this.schedule);
			console.log("Sensor", this.selectedSensor);

			// Settaggio metodo di registrazione
			console.log("Recovering actual configuration settings...");
			console.log("Send command to device...");
			await this.commandConsole.sendCommand({
				cmd: "PRINT_CONFIGURATION",
				params: ["not-show-noty"],
				user: JSON.parse(localStorage.getItem('user')) || {}
			}, localStorage.getItem("deviceId"));

			console.log("Retrive data from server...");
			var actualLockedRequired = await axios.get(`${apiUrl}/device/recording-screen/locked`);

			const modeNotSure = true; ///TODO: Rimuovere una volta scelto un metodo
			if (actualLockedRequired != null && actualLockedRequired.data != null && actualLockedRequired.data !== "") {
				localStorage.setItem("requireScreenLocked", actualLockedRequired.data);
				this.requireScreenLocked = actualLockedRequired.data;
				this.notSure = false;
			} else if (this.requireScreenLocked != null) {
				/// TODO: Rimuovere una delle due modalità
				if (modeNotSure) {
					this.notSure = true;
				} else {
					localStorage.removeItem("requireScreenLocked");
					this.requireScreenLocked = null;
					this.notSure = false;
				}
			}

			console.log("Configuration settings retrived!");
		},
		mounted() {
			this.requireScreenLocked = JSON.parse(localStorage.getItem("requireScreenLocked"));
		}
	}
</script>

<style scoped>
</style>