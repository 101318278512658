import axios from 'axios';
import { apiUrl } from "@/common/endpoint.const";
const instance = axios.create({
	baseURL: apiUrl,
	timeout: 30000,
})

instance.interceptors.request.use(
	config => {
		var token = localStorage.getItem('token');

		if (token)
			config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

		if (localStorage.getItem("deviceId"))
			config.headers['athena-deviceid'] = localStorage.getItem('deviceId');

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export default instance;